import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import config from '../config';
import notificationService from '../services/notificationSV';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [csrfToken, setCsrfToken] = useState(null);
  const wsConnected = useRef(false);

  const connectWebSocket = async (userId) => {
    if (!wsConnected.current) {
      try {
        await notificationService.connect(userId, 'teacher');
        wsConnected.current = true;
      } catch (error) {
        console.error('WebSocket连接失败:', error);
        // 不要阻止应用继续运行
      }
    }
  };

  const checkAuth = async () => {
    const cachedUser = localStorage.getItem('user');
    const cachedCsrfToken = localStorage.getItem('csrfToken');
    if (cachedUser && cachedCsrfToken) {
      const parsedUser = JSON.parse(cachedUser);
      setUser(parsedUser);
      setCsrfToken(cachedCsrfToken);
      await connectWebSocket(parsedUser.id);
      setLoading(false);
      return;
    }
  
    try {
      const response = await axios.get(`${config.API_BASE_URL}/check_auth.php`, { withCredentials: true });
      if (response.data.authenticated) {
        setUser(response.data.user);
        setCsrfToken(response.data.csrfToken);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        localStorage.setItem('csrfToken', response.data.csrfToken);
        await connectWebSocket(response.data.user.id);
      } else {
        setUser(null);
        setCsrfToken(null);
        localStorage.removeItem('user');
        localStorage.removeItem('csrfToken');
        notificationService.disconnect();
        wsConnected.current = false;
      }
    } catch (error) {
      console.error('Authentication check failed:', error);
      setUser(null);
      setCsrfToken(null);
      localStorage.removeItem('user');
      localStorage.removeItem('csrfToken');
      notificationService.disconnect();
      wsConnected.current = false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
    return () => {
      notificationService.disconnect();
      wsConnected.current = false;
    };
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${config.API_BASE_URL}/login.php`, { email, password }, { withCredentials: true });
      if (response.data.success) {
        setUser(response.data.user);
        setCsrfToken(response.data.csrfToken);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        localStorage.setItem('csrfToken', response.data.csrfToken);
        await connectWebSocket(response.data.user.id);
        return { success: true };
      }
      return { success: false, message: response.data.message };
    } catch (error) {
      console.error('Login failed:', error);
      return { success: false, message: 'An error occurred during login.' };
    }
  };

  const logout = async () => {
    try {
      await axios.post(`${config.API_BASE_URL}/logout.php`, {}, { withCredentials: true });
      setUser(null);
      setCsrfToken(null);
      localStorage.removeItem('user');
      localStorage.removeItem('csrfToken');
      notificationService.disconnect();
      wsConnected.current = false;
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // 创建一个带有 CSRF 令牌的 axios 实例
  const axiosWithCsrf = axios.create({
    withCredentials: true,
    baseURL: config.API_BASE_URL,
  });

  axiosWithCsrf.interceptors.request.use(config => {
    if (csrfToken) {
      config.headers['X-CSRF-Token'] = csrfToken;
    }
    return config;
  });

  const value = {
    user,
    loading,
    login,
    logout,
    checkAuth,
    csrfToken,
    axiosWithCsrf
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};