// src/Layout.js
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bell, Home, Users, FileText, ChevronRight, BookOpen, User, LogOut, AlertCircle, Phone, Mail, MapPin, ExternalLink } from 'lucide-react';
import { useAuth } from './contexts/AuthContext';
import { useNavigate, Outlet, NavLink } from 'react-router-dom';
import logo from './new_logo.png';
import { loadAvatarImage } from './utils/avatarLoader';
import notificationService from './services/notificationSV';
import { setUnreadNotifications, addNotification, removeNotification, clearAllNotifications, fetchUnreadNotifications } from './store/notificationsSlice';
import moment from 'moment-timezone';

const Layout = () => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const timeoutRef = useRef(null);
  const notificationRef = useRef(null);
  const [avatar, setAvatar] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications.items);
  const unreadNotifications = useSelector(state => state.notifications.unreadItems);


  useEffect(() => {
    if (user) {
      console.log('Setting up notification service for user:', user.id);
      
      notificationService.connect(user.id, 'teacher')
        .then(() => {
          console.log('WebSocket connection established');
          // 连接成功后获取未读通知
          dispatch(fetchUnreadNotifications());
        })
        .catch(error => {
          console.error('Failed to connect to notification service:', error);
        });
      
      const handleNewNotification = (notification) => {
        console.log('Received new notification in Layout:', notification);
        dispatch(addNotification(notification));
      };
  
      const handleUnreadNotifications = (notifications) => {
        console.log('Received unread notifications:', notifications);
        dispatch(setUnreadNotifications(notifications));
      };
  
      notificationService.addListener('new_notification', handleNewNotification);
      notificationService.addListener('unread_notifications', handleUnreadNotifications);
  
      return () => {
        console.log('Cleaning up notification service');
        notificationService.removeListener('new_notification', handleNewNotification);
        notificationService.removeListener('unread_notifications', handleUnreadNotifications);
        notificationService.disconnect();
      };
    }
  }, [user, dispatch]);
  
  // 添加调试日志
  useEffect(() => {
    console.log('未读通知:', unreadNotifications);
  }, [unreadNotifications]);

  const loadAvatar = async () => {
    if (user && user.id) {
      try {
        const avatarUrl = await loadAvatarImage(user.id, user.name);
        setAvatar(avatarUrl);
      } catch (error) {
        console.error('Error loading avatar:', error);
      }
    }
  };

  useEffect(() => {
    if (user) {
      loadAvatar();
    }
  }, [user]);

  const toggleNotifications = () => {
    setShowNotifications(prev => !prev);
    
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    
    if (!showNotifications && unreadNotifications.length === 0) {
      timeoutRef.current = setTimeout(() => {
        setShowNotifications(false);
      }, 2000);
    }
  };

  const handleNotificationClick = async (notification) => {
    try {
      await notificationService.markAsRead(notification.id);
      dispatch(removeNotification(notification.id));
      
      // 根据通知类型导航到相应页面
      switch(notification.type) {
        case 'class_reminder':
          navigate(`/class/${notification.course_id}`);
          break;
        case 'homework_submission':
          navigate(`/homework/${notification.homework_id}`);
          break;
        case 'sent_confirmation':
          // 对于确认通知，可能不需要导航
          break;
        default:
          navigate('/notifications');
      }
  
      // 如果没有未读通知了，关闭通知面板
      if (unreadNotifications.length === 1) {
        setShowNotifications(false);
      }
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
  
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

    const handleMarkAllAsRead = async () => {
    try {
      await notificationService.markAllAsRead();
      dispatch(clearAllNotifications());
      setShowNotifications(false);
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotifications(false);
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleLogout = () => {
    if (navigate) {
      navigate('/login');
    } else {
      console.error('Navigation is not available');
    }
  };

  const showNavBar = () => {
    setShowNav(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const hideNavWithDelay = () => {
    timeoutRef.current = setTimeout(() => {
      setShowNav(false);
    }, 1000);
  };

  const handleProfileClick = () => {
    navigate('/teacher-profile');
  };
  
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (e.clientY <= 80) {
        showNavBar();
      } else if (e.clientY > 100) {
        hideNavWithDelay();
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleLogoClick = () => {
    navigate('/home');
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* Fixed Header */}
      <header className="fixed top-0 left-0 right-0 bg-gradient-to-r from-sky-400 to-indigo-500 shadow-md z-50">
        <div className="container mx-auto px-8 py-2 flex justify-between items-center">
          <img 
            src={logo} 
            alt="Theory ABC Logo" 
            className="h-6 md:h-6 lg:h-6 w-auto drop-shadow-lg filter brightness-110 cursor-pointer" 
            onClick={handleLogoClick}
          />
          <div className="flex items-center space-x-6">
            <div className="relative" ref={notificationRef}>
              <Bell 
                size={25} 
                className="cursor-pointer text-white"
                onClick={toggleNotifications}
              />
              {unreadNotifications.length > 0 && (
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                  {unreadNotifications.length}
                </span>
              )}
                    {showNotifications && (
              <div className="absolute -left-40 mt-2 w-96 bg-gray-100 rounded-sm shadow-lg overflow-hidden z-20">
                <div className="bg-gray-100 text-orange-500 px-4 py-2 font-bold text-lg ">
                  Notifications
                </div>
                <div className="max-h-96 overflow-y-auto">
                  {unreadNotifications.length > 0 ? (
                    <>
                      {unreadNotifications.slice(0, 10).map((notification, index) => (
                        <div 
                          key={notification.id} 
                          className="px-4 py-3 text-sm text-gray-700 hover:bg-gray-200 cursor-pointer border-b border-gray-200"
                          onClick={() => handleNotificationClick(notification)}
                        >
                          <p className="mb-1">
                            <span className="font-medium">{index + 1}. </span>
                            {notification.content}
                          </p>
                          <p className="text-xs text-gray-500">
                          {moment(notification.timestamp).format('YYYY.MM.DD HH:mm:ss')}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="px-4 py-3 text-sm text-gray-700">No unread notifications</div>
                  )}
                </div>
                <div className="flex justify-between items-center bg-gray-200 px-4 py-2 text-sm">
                  <button 
                    onClick={() => navigate('/notifications')}
                    className="text-orange-500 hover:text-orange-800 font-semibold flex items-center"
                  >
                    More <ChevronRight size={16} className="ml-1" />
                  </button>
                  <button 
                    onClick={handleMarkAllAsRead}
                    className="text-orange-500 hover:text-orange-800 font-semibold"
                  >
                    Mark All as Read
                  </button>
                </div>
              </div>
            )}
            </div>
            <div 
              className="flex items-center cursor-pointer" 
              onClick={handleProfileClick}
            >
              {avatar ? (
                <img 
                  src={avatar} 
                  alt={`Teacher ${user?.name}`} 
                  className="w-9 h-9 rounded-full object-cover object-center shadow-md"
                />
              ) : (
                <div className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center">
                  <span className="text-gray-600 text-xs">{user?.name?.charAt(0)}</span>
                </div>
              )}
              <span className="font-bold text-white ml-2">Teacher {user?.name}</span>
            </div>
            <div className="relative group">
              <LogOut 
                size={20} 
                onClick={handleLogout} 
                className="text-white cursor-pointer"
              />
              <span className="absolute top-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                Logout
              </span>
            </div>
          </div>
        </div>
      </header>

      {/* 可隐藏的导航栏 */}
      <nav 
        className={`fixed top-12 left-0 right-0 bg-white shadow z-20 transition-all duration-600 ${
          showNav ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onMouseEnter={showNavBar}
        onMouseLeave={hideNavWithDelay}
      >
        <div className="container mx-auto px-8 py-3">
          <ul className="flex justify-between text-rg items-center">
            <li>
              <NavLink to="/" className={({isActive}) => isActive ? "nav-item text-green-500 text-b-2 border-b-2 border-green-500 flex items-center" : "nav-item flex items-center"}>
                <Home size={25} className="mr-1" /> Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/class" className={({isActive}) => isActive ? "nav-item text-green-500 border-b-2 border-green-500 flex items-center" : "nav-item flex items-center"}>
                <Users size={25} className="mr-1" /> Class
              </NavLink>
            </li>
            <li>
              <NavLink to="/classrecorder" className={({isActive}) => isActive ? "nav-item text-green-500 border-b-2 border-green-500 flex items-center" : "nav-item flex items-center"}>
                <FileText size={25} className="mr-1" /> Class Records
              </NavLink>
            </li>
            <li>
              <NavLink to="/webinar-and-training" className={({isActive}) => isActive ? "nav-item text-green-500 border-b-2 border-green-500 flex items-center" : "nav-item flex items-center"}>
                <BookOpen size={25} className="mr-1" /> Webinar and Training
              </NavLink>
            </li>
            <li>
              <NavLink to="/teacher-profile" className={({isActive}) => isActive ? "nav-item text-green-500 border-b-2 border-green-500 flex items-center" : "nav-item flex items-center"}>
                <User size={25} className="mr-1" /> Teacher Profile
              </NavLink>
            </li>
            <li>
              <NavLink to="/notifications" className={({isActive}) => isActive ? "nav-item text-green-500 border-b-2 border-green-500 flex items-center" : "nav-item flex items-center"}>
                <Bell size={25} className="mr-1" /> Notification
              </NavLink>
            </li>
            <li><NavLink to="/teacher-profile" className={({isActive}) => isActive ? "nav-item text-green-500 border-b-2 border-green-500" : "nav-item"}></NavLink></li>
            <li><NavLink to="/teacher-profile" className={({isActive}) => isActive ? "nav-item text-green-500 border-b-2 border-green-500" : "nav-item"}></NavLink></li>
          </ul>
        </div>
      </nav>

      {/* Main Content */}
      <div className="flex-grow pt-20 pb-16"> {/* Added pb-16 for footer space */}
        <main className="container mx-auto px-8 py-2">
          <Outlet />
        </main>
      </div>

      {/* Footer */}
          {/* Footer */}
     <footer className="bg-gradient-to-r from-purple-300 to-blue-400 text-gray-600 py-2 mt-6">
        <div className="container mx-auto px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <h3 className="font-semibold text-base mb-2 flex items-center">
                <Phone size={16} className="mr-2" /> Contact Us
              </h3>
              <p className="text-sm flex items-center">
                <Phone size={14} className="mr-2" /> Tel: +86 21-51603680 / +1 508-645-6286
              </p>
              <p className="text-sm flex items-center">
                <Mail size={14} className="mr-2" /> Email: hr@theoryabc.com
              </p>
            </div>
            <div>
              <h3 className="font-semibold text-base mb-2 flex items-center">
                <MapPin size={16} className="mr-2" /> Address
              </h3>
              <p className="text-sm">1-30 Minbei Road, Building 104,</p>
              <p className="text-sm">A District, 1st Floor,</p>
              <p className="text-sm">Minhang District, Shanghai, China</p>
            </div>
            <div>
              <h3 className="font-semibold text-base mb-2 flex items-center">
                <ExternalLink size={16} className="mr-2" /> Quick Links
              </h3>
              <ul className="text-sm">
                <li><a href="#" className="hover:text-green-500 flex items-center"><ExternalLink size={14} className="mr-2" /> About Us</a></li>
                <li><a href="#" className="hover:text-green-500 flex items-center"><ExternalLink size={14} className="mr-2" /> FAQ</a></li>
                <li><a href="#" className="hover:text-green-500 flex items-center"><ExternalLink size={14} className="mr-2" /> Privacy Policy</a></li>
                <li><a href="#" className="hover:text-green-500 flex items-center"><ExternalLink size={14} className="mr-2" /> Terms of Service</a></li>
              </ul>
            </div>
          </div>
          <div className="border-t border-gray-200 mt-4 pt-4 text-xs text-center">
            <p>Theory ABC (Shanghai) Education Technology Co., Ltd. &copy; {new Date().getFullYear()} All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;