import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import notificationService from '../services/notificationSV';

export const fetchUnreadNotifications = createAsyncThunk(
  'notifications/fetchUnread',
  async () => {
    const unreadNotifications = await notificationService.fetchUnreadNotifications();
    return unreadNotifications;
  }
);

export const fetchAllNotifications = createAsyncThunk(
  'notifications/fetchAll',
  async () => {
    const allNotifications = await notificationService.fetchAllNotifications();
    return allNotifications;
  }
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    allItems: [],
    unreadItems: [],
    status: 'idle',
    error: null
  },
  reducers: {
    setAllNotifications: (state, action) => {
      state.allItems = action.payload;
      localStorage.setItem('allNotifications', JSON.stringify(action.payload));
    },
    setUnreadNotifications: (state, action) => {
      state.unreadItems = action.payload;
      state.status = 'succeeded';
      localStorage.setItem('unreadNotifications', JSON.stringify(action.payload));
    },
    addNotification: (state, action) => {
      state.allItems.unshift(action.payload);
      state.unreadItems.unshift(action.payload);
      localStorage.setItem('allNotifications', JSON.stringify(state.allItems));
      localStorage.setItem('unreadNotifications', JSON.stringify(state.unreadItems));
    },
    removeNotification: (state, action) => {
      state.unreadItems = state.unreadItems.filter(n => n.id !== action.payload);
      // 更新 allItems 中的通知状态
      const index = state.allItems.findIndex(n => n.id === action.payload);
      if (index !== -1) {
        state.allItems[index].read = true;
      }
      localStorage.setItem('unreadNotifications', JSON.stringify(state.unreadItems));
      localStorage.setItem('allNotifications', JSON.stringify(state.allItems));
    },
    clearAllNotifications: (state) => {
      state.unreadItems = [];
      state.allItems = state.allItems.map(n => ({...n, read: true}));
      localStorage.removeItem('unreadNotifications');
      localStorage.setItem('allNotifications', JSON.stringify(state.allItems));
    },
    setError: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    updateAllNotifications: (state, action) => {
      state.allItems = action.payload;
      localStorage.setItem('allNotifications', JSON.stringify(action.payload));
    },

    addNotification: (state, action) => {
      state.allItems.unshift(action.payload);
      state.unreadItems.unshift(action.payload);
      localStorage.setItem('allNotifications', JSON.stringify(state.allItems));
      localStorage.setItem('unreadNotifications', JSON.stringify(state.unreadItems));
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchUnreadNotifications.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchUnreadNotifications.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.unreadItems = action.payload;
      localStorage.setItem('unreadNotifications', JSON.stringify(action.payload));
    })
    .addCase(fetchUnreadNotifications.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(fetchAllNotifications.fulfilled, (state, action) => {
      state.allItems = action.payload;
      localStorage.setItem('allNotifications', JSON.stringify(action.payload));
    });
}
});

export const { 
  setAllNotifications,
  setUnreadNotifications, 
  addNotification, 
  removeNotification, 
  clearAllNotifications, 
  setError,
  updateAllNotifications 
} = notificationsSlice.actions;

export default notificationsSlice.reducer;