// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import notificationsReducer from './notificationsSlice';


const store = configureStore({
  reducer: {
    notifications: notificationsReducer,
    // 其他 reducers...
  },
});

export default store;