import io from 'socket.io-client';

class NotificationService {
    constructor() {
      this.socket = null;
      this.userId = null;
      this.userType = null;
      this.listeners = new Map();
      this.isConnected = false;
      this.connectionPromise = null;
      this.listenersSetup = false;
      this.recentNotifications = new Set();
    }

    connect(userId, userType) {
        if (this.isConnected) {
          console.log('已经连接到通知服务器');
          return Promise.resolve();
        }

        if (this.connectionPromise) {
          return this.connectionPromise;
        }

        this.connectionPromise = new Promise((resolve, reject) => {
          if (this.socket) {
            this.socket.removeAllListeners();
          }
          this.userId = userId;
          this.userType = userType;
          this.socket = io('https://teacher.theoryabc.com', {
            query: { userId, userType }
          });

          this.socket.on('connect', () => {
            console.log('已连接到通知服务器');
            this.isConnected = true;
            this.notifyListeners('connect');
            resolve();
          });

          this.socket.on('connect_error', (error) => {
            console.error('连接错误:', error);
            this.notifyListeners('connect_error', error);
            reject(error);
          });

          this.setupListeners();
        });

        return this.connectionPromise;
    }

    setupListeners() {
      if (this.listenersSetup) return;
      this.listenersSetup = true;

      this.socket.on('notifications_update', (notifications) => {
        console.log('收到通知更新:', notifications);
        const unreadNotifications = notifications.filter(n => !n.read);
        this.notifyListeners('unread_notifications', unreadNotifications);
        this.notifyListeners('notifications_update', notifications);
      });

      this.socket.on('new_notification', (notification) => {
        console.log('收到新通知:', notification);
        this.notifyListeners('new_notification', notification);
      });

      this.socket.on('notification_marked_as_read', (notificationId) => {
        console.log('通知已标记为已读:', notificationId);
        this.notifyListeners('notification_marked_as_read', notificationId);
      });

      this.socket.on('all_notifications_marked_as_read', () => {
        console.log('所有通知已标记为已读');
        this.notifyListeners('all_notifications_marked_as_read');
      });

      this.socket.on('notification_error', (error) => {
        console.error('通知错误:', error);
        this.notifyListeners('notification_error', error);
      });

      this.socket.on('disconnect', () => {
        console.log('与通知服务器断开连接');
        this.isConnected = false;
        this.connectionPromise = null;
        this.listenersSetup = false;
        this.notifyListeners('disconnect');
      });
    }

    addListener(event, callback) {
        if (!this.listeners.has(event)) {
          this.listeners.set(event, new Set());
        }
        this.listeners.get(event).add(callback);
    }

    removeListener(event, callback) {
        if (this.listeners.has(event)) {
          this.listeners.get(event).delete(callback);
        }
    }

    notifyListeners(event, data) {
        console.log(`通知监听器事件: ${event}`, data);
        if (this.listeners.has(event)) {
          this.listeners.get(event).forEach(callback => callback(data));
        }
    }

    sendNotification(type, recipient, data) {
        console.log('NotificationService: 开始发送通知', { type, recipient, data });
        const notificationKey = `${type}_${recipient}_${JSON.stringify(data)}`;
        if (this.recentNotifications.has(notificationKey)) {
          console.log('重复的通知，已忽略');
          return Promise.resolve();
        }
        this.recentNotifications.add(notificationKey);
        setTimeout(() => this.recentNotifications.delete(notificationKey), 5000); // 5秒后删除记录

        return this.ensureConnection().then(() => {
            return new Promise((resolve, reject) => {
                const notificationData = {
                    type,
                    sender: this.userId,
                    recipient,
                    content: '您有一个新的通知',
                    context: data
                };
                console.log('NotificationService: 发送WebSocket事件', notificationData);
                this.socket.emit('send_notification', notificationData, (response) => {
                    console.log('NotificationService: 收到WebSocket响应', response);
                    if (response.success) {
                        console.log('NotificationService: 通知发送成功');
                        resolve(response);
                    } else {
                        console.error('NotificationService: 通知发送失败', response.message);
                        reject(new Error(response.message));
                    }
                });
            });
        }).catch(error => {
            console.error('NotificationService: 发送通知时出错', error);
            throw error;
        });
    }

    markAsRead(notificationId) {
        return this.ensureConnection().then(() => {
            return new Promise((resolve, reject) => {
                this.socket.emit('mark_as_read', { notificationId }, (response) => {
                    if (response.success) {
                        resolve(response);
                    } else {
                        reject(new Error(response.message));
                    }
                });
            });
        });
    }

    markAllAsRead() {
        return this.ensureConnection().then(() => {
            return new Promise((resolve, reject) => {
                this.socket.emit('mark_all_as_read', (response) => {
                    if (response.success) {
                        resolve(response);
                    } else {
                        reject(new Error(response.message));
                    }
                });
            });
        });
    }

    fetchUnreadNotifications() {
        return this.ensureConnection().then(() => {
            return new Promise((resolve, reject) => {
                this.socket.emit('get_unread_notifications', (response) => {
                    if (response.success) {
                        resolve(response.notifications);
                    } else {
                        reject(new Error(response.message));
                    }
                });
            });
        });
    }

    fetchAllNotifications() {
        return this.ensureConnection().then(() => {
            return new Promise((resolve, reject) => {
                this.socket.emit('get_all_notifications', (response) => {
                    if (response.success) {
                        console.log('NotificationService: 获取所有通知成功', response.notifications);
                        resolve(response.notifications);
                    } else {
                        console.error('NotificationService: 获取所有通知失败', response.message);
                        reject(new Error(response.message));
                    }
                });
            });
        });
    }

    deleteNotification(notificationId) {
        return this.ensureConnection().then(() => {
          return new Promise((resolve, reject) => {
            this.socket.emit('delete_notification', { notificationId }, (response) => {
              if (response.success) {
                console.log('通知删除成功:', notificationId);
                resolve(response);
              } else {
                console.error('通知删除失败:', response.message);
                reject(new Error(response.message));
              }
            });
          });
        });
      }

    ensureConnection() {
        if (this.isConnected) {
            return Promise.resolve();
        }
        return this.connect(this.userId, this.userType);
    }

    disconnect() {
        if (this.socket) {
          this.socket.disconnect();
          this.isConnected = false;
          this.connectionPromise = null;
          this.listenersSetup = false;
        }
    }
}

export default new NotificationService();